/*
 *
 */

import React from 'react';
import Section from 'components/section';
import { useIntl } from 'react-intl';
import Helmet from 'react-helmet';
import AnnouncementSection from 'components/announcement';




/*
 *
 */

export default function Carte () {

	const intl = useIntl();

	return (
		<React.Fragment>
			<Helmet>
				<title>{intl.formatMessage({ id: 'page.carte.meta_title' })}</title>
				<meta name="description" content={intl.formatMessage({ id: 'page.carte.meta_desc' })} />
			</Helmet>
			<AnnouncementSection>
				<p>Zoomez sur la carte pour voir plus de restaurant apparaitre autour de vous, vous pouvez également <a href={intl.formatMessage({ id: 'global.link.mapstr.link' })}>Nous suivre sur Mapstr</a> </p>
			</AnnouncementSection>
			<Section spacing="no-padding">
				<iframe 
					title={intl.formatMessage({ id: 'page.carte.hero.title' })}
					src={intl.formatMessage({ id: 'global.link.mapstr.embed' })}
					allowfullscreen
					width="100%" 
					height="780" 
					frameBorder="0" 
					marginHeight="0" 
					marginWidth="0">Loading…</iframe>
			</Section>

		</React.Fragment>
	);
}